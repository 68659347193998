<template>
    <div ref="containerRef" 
        class="card shadow dropdown-menu rounded-0 px-2" 
        :style="{width:containerWidth+'px', 'min-width': minWidth+'px'}">
        <slot name="title">
            <span class="text-muted" style="font-weight:bold">
                    Will update <span class="text-danger">{{selectedRows.length}}</span>
                    records
            </span>
        </slot>
        <slot name="editor" 
            :bulkItem="bulkItem" 
            :column="column"
            >
            <editor-renderer 
                v-model="bulkItem" 
                :column="column" 
                :override="bulkUpdateEditor">
            </editor-renderer>
        </slot>
        <div class="d-flex pt-2">
            <slot name="actions">
                <div class="ms-auto">
                    <button class="btn btn-primary btn-sm" @click="handleOk" 
                        v-if="selectedRows.length>0 "
                        >OK</button>
                    <button class="btn btn-secondary btn-sm ms-1" @click="handleCancel">Cancel</button>
                </div>
            </slot>
        </div>
    </div>
</template>

<script setup>
import 'o365.libraries.modules.DataObject.extensions.BulkUpdate.ts';
import { OTextEditor, ONumberEditor, OBitEditor, ODateEditor } from 'o365-ui-components';
import { h, ref, computed, inject, resolveComponent,isProxy } from 'vue';
const props = defineProps({
    dataObject: {
        type: Object,
        required: true
    },
    column: {
        type: Object,
        required: true
    },
    bulkUpdateEditor: null,
    bulkFields: {
        default(rawProps) {
            return rawProps.column.bulkFields ?? [rawProps.column.name]
        }
    },
    dataGridControl: Object,
    width: Number,
    minWidth: {
        type: Number,
        default: 200
    },
    formulaEditor: Boolean,
});

const emit = defineEmits(['oncancel']);

function confirm(...args) {
    return import('o365-vue-services').then(services => services.confirm(...args));
}

function EditorRenderer(props, context) {
    let renderer;
    if (props.override) {
        renderer = override;
    } else if (props.column.bulkUpdateEditorSlot) {
        renderer = props.column.bulkUpdateEditorSlot;
    } else if (props.column.bulkUpdateEditor) {
        renderer = props.column.bulkUpdateEditor;
    } else if (props.column.cellEditorSlot) {
        renderer = props.column.cellEditorSlot;
    } else if (props.column.cellEditor) {
        renderer = props.column.cellEditor;
    }

    let singleField = false;
    if (typeof renderer === 'string') {
        const resolve = inject('resolveComponent');
        switch (renderer) {
            case 'OTextEditor':
                renderer = OTextEditor;
                singleField = true;
                break;
            case 'ONumberEditor':
                renderer = ONumberEditor;
                singleField = true;
                break;
            case 'OBitEditor':
                renderer = OBitEditor;
                singleField = true;
                break;
            case 'ODateEditor':
                renderer = ODateEditor;
                singleField = true;
                break;
            default:
                renderer = resolve(renderer) ?? resolveComponent(renderer);
                singleField = false;
        }
    }
    
    if (renderer) {
        return h(renderer, {
            modelValue: singleField ? props.modelValue[props.column.field] : props.modelValue,
            'onUpdate:modelValue': (value) => {
                console.log(renderer);
                if (singleField) {
                    const bulkItem = props.modelValue;
                    bulkItem[props.column.field] = value;
                    context.emit('update:modelValue', bulkItem)
                } else {
                    context.emit('update:modelValue', value)
                }
            },
            row: props.modelValue,
            column: props.column,
            isBulkEditor: true
        });
    } else {
        return h('span', { class: 'text-danger' }, 'No editor provided or resolve failed');
    }
};
EditorRenderer.props = ['modelValue', 'row', 'column', 'override'];
EditorRenderer.emits = ['update:modelValue'];

const containerRef = ref(null);
const bulkItem = ref({});

const selectedRows = computed(() => {
    // console.log('selectedRows');
    let rows = props.dataObject.data.filter((item) => item.isSelected); 
    if(props.dataObject.data.filter((item) => item.isSelected).length < props.dataGridControl.dataObject.selectionControl.selectedUniqueKeys.size){
        rows = Array.from(props.dataGridControl.dataObject.selectionControl.selectedUniqueKeys)
    }

    if (props.dataGridControl
            .dataObject
            .selectionControl.isValidSelection && rows.length === 0) {
        const selectedColumn = props.dataGridControl
            .dataObject
            .selectionControl
            .getColumns(props.dataGridControl.dataColumns.columns)
            .find(x => x.field===props.column.field);
                    
        if (selectedColumn) {
            rows = props.dataObject.selectionControl.getRows(props.dataObject.data);
        }
    }
    return rows;
});

const inputPlaceholder = computed(() => {
    const fieldName = props.column.caption;
    return `replace ${fieldName} with...`;
});


const containerWidth = computed(() => {
    return props.width ?? props.column?.width ?? 'auto';
});

async function doBulkUpdate(options){

    try {
        const response = await props.dataObject.bulkUpdate.doBulkUpdate({
            'bulkItem': options.bulkItem,
            'selectedItems': options.selectedRows,
            'bulkFields': options.bulkFields,
            getProgress: (pProgress) => {
                showProgressDialog(pProgress);
            },
        });
        if (response.error) {
            import('o365-vue-services').then(alertModule => {
                alertModule.alert(response.error);
            });
        } else {
            // const bulkUpdatedItem = options.bulkItem;
            // selectedRows.value.forEach(item => {
                // props.dataObject.storage.updateItem(item.index, bulkUpdatedItem, true)
            // });
        }
    } catch (ex) {
        console.log('error: ', ex);
    }


/*
    await props.dataObject.bulkUpdate.doBulkUpdate({
            'bulkItem': options.bulkItem,
            'selectedItems': options.selectedRows.value,
            'bulkFields': options.bulkFields
        }).then(function(data){
            const storage = props.dataObject.storage;
            const bulkUpdatedItem = this.bulkItem;
            debugger
            selectedRows.value.forEach(function(item){
                storage.updateItem(item.index, bulkUpdatedItem, true)
            });
            
        }.bind(options))
        .catch(function(error){
            console.log("handle error", error)
        });
        */
    
}

async function handleOk() {
    try {
        if (containerRef.value) {
            containerRef.value.style.display = 'none';
        }
        await confirm({
            title: 'Bulk update',
            message: `Are you sure you want to update ${props.column.headerName} for the ${selectedRows.value.length} selected records`,
            btnClassOk: 'btn-danger',
            btnTextOk: 'Update',
        });
        let item = {};
        item[props.column.field] = isProxy(bulkItem.value) ? null:bulkItem.value;
        if(typeof(bulkItem.value) === 'object'
            && bulkItem.value
            && Object.keys(bulkItem.value)?.length > 0 ){
            item = bulkItem.value;
        }else if(item[props.column.field] == null && props.column && props.column.boundFields){
            props.column.boundFields.forEach(f=>{
                if(f[0] && !item.hasOwnProperty(f[0]) ) item[f[0]] = null;
            })
        }

        if (typeof props.column.bulkUpdateApplyFunction === 'function') {
            props.column.bulkUpdateApplyFunction({
                bulkItem: item,
                bulkFields: props.bulkFields,
                selectedRows: selectedRows.value,
            });
        } else {
            await doBulkUpdate({
                bulkItem: item,
                bulkFields: props.bulkFields,
                selectedRows: selectedRows,
            });
        }

    } catch (e) {
        handleCancel();
    }
    finally {
        if (containerRef.value) {
            containerRef.value.style.display = '';
        }
    }
}

function showProgressDialog(pBulkProgress) {
    if (props.dataGridControl) {
        props.dataGridControl.header.activeBulkUpdates.push(pBulkProgress);
    }
}

function handleCancel() {
    emit('oncancel');
}

function getEmptyBulkItem() {
    const item = {};
    return item;
}

</script>
